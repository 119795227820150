import styled from "@emotion/styled";
import { Box, Button, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";

import { mutateRoutingOutsources } from "@/api/routingOutsource/useRoutingOutsourcesQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import customAlert from "@/features/ui/alert/alert";
import { LocationAutoComplete } from "@/features/ui/autoComplete/location/location-autoComplate";
import { PartnerAutoComplete } from "@/features/ui/autoComplete/partner/partner-autoComplete";
import { RoutingAutoComplete } from "@/features/ui/autoComplete/routing/routing-autoComplete";
import { handleErrorResponse } from "@/utils/errorMessage";
import { validateFormValues } from "@/utils/validation";
import { DefaultApiRoutingOutsourcesPostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

interface RoutingOutsourceProps {
  name?: string;
  autoCompleteOnSubmit?: (values: any) => void;
}
export const RoutingOutsourceCreateForm = (params: RoutingOutsourceProps) => {
  const { name, autoCompleteOnSubmit } = params;
  const { closeModal } = useModal();
  const { t } = useTranslation();

  const queryClient = useQueryClient();
  const { mutate: postMutate } = useMutation(
    (params: DefaultApiRoutingOutsourcesPostRequest) =>
      mutateRoutingOutsources
        .create(params)
        .mutationFn(params as DefaultApiRoutingOutsourcesPostRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["routingOutsources"]);
        closeModal(form.values);
        customAlert(
          t("message.createSuccess", { item: t("outsource.outsource") }),
          t("common.createSuccess"),
          "green",
        );
      },
      onError: (error: any) => {
        customAlert(
          handleErrorResponse({ error, errorContext: t("outsource.code") }),
          t("common.createFail", { item: t("outsource.outsource") }),
          "red",
        );
      },
    },
  );

  const form = useForm({
    initialValues: {
      name: name ?? undefined,
      routingCode: undefined,
      fromLocationCode: undefined,
      toLocationCode: undefined,
      partnerCode: undefined,
    },
  });

  const onSubmit = async () => {
    try {
      postMutate({
        routingOutsourcesGetRequest: {
          name: name ?? form.values.name!,
          routingCode: form.values.routingCode!,
          fromLocationCode: form.values.fromLocationCode!,
          toLocationCode: form.values.toLocationCode!,
          partnerCode: form.values.partnerCode,
        },
      });
    } catch (error) {
      customAlert(
        handleErrorResponse({ error, errorContext: t("outsource.code") }),
        t("common.createFail", { item: t("outsource.outsource") }),
        "red",
      );
    }
  };

  return (
    <Box w={420} mx="auto">
      <Form>
        <ModalFieldSet>
          <TextInput
            label={t("outsource.name")}
            placeholder={t("placeholder.input", { item: t("outsource.name") })}
            withAsterisk
            {...form.getInputProps("name")}
          />
          <PartnerAutoComplete
            label={t("partner.code")}
            placeholder={t("placeholder.select", { item: t("partner.code") })}
            withAsterisk
            width={"100%"}
            {...form.getInputProps("partnerCode")}
          />
          <RoutingAutoComplete
            label={t("routing.code")}
            placeholder={t("placeholder.select", { item: t("routing.code") })}
            withAsterisk
            width={"100%"}
            {...form.getInputProps("routingCode")}
          />
          <LocationAutoComplete
            label={t("outsource.fromLocationCode")}
            placeholder={t("placeholder.select", { item: t("outsource.fromLocationCode") })}
            withAsterisk
            width={"100%"}
            {...form.getInputProps("fromLocationCode")}
          />
          <LocationAutoComplete
            label={t("outsource.toLocationCode")}
            placeholder={t("placeholder.select", { item: t("outsource.toLocationCode") })}
            withAsterisk
            width={"100%"}
            {...form.getInputProps("toLocationCode")}
          />
          <ModalConfirmBtnBox>
            <Button color="gray" onClick={closeModal}>
              {t("common.cancel")}
            </Button>
            <Button
              disabled={validateFormValues({
                pageType: "routingOutsource",
                values: form.values,
              })}
              onClick={autoCompleteOnSubmit ? () => autoCompleteOnSubmit(form.values) : onSubmit}
            >
              {t("common.create", { item: `${t("outsource.outsource")}` })}
            </Button>
          </ModalConfirmBtnBox>
        </ModalFieldSet>
      </Form>
    </Box>
  );
};

const Form = styled.form`
  width: 100%;
  height: 100%;
`;

const ModalFieldSet = styled.fieldset`
  border: none;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ModalConfirmBtnBox = styled.div`
  padding: 24px 0;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`;
