import { Table } from "@mantine/core";

export type WorkTableProps = {
  children: React.ReactNode;
  isWithBorder?: boolean; // border를 부여할지에 대한 여부. 생산계획 테이블에서는 border를 부여하지 않고, 작업 테이블에서는 border를 부여한다.
};

export const WorkTable = ({ children, isWithBorder }: WorkTableProps) => {
  return (
    <Table
      withBorder={isWithBorder}
      withColumnBorders
      style={{ borderCollapse: "separate", borderSpacing: 0, zIndex: 0 }}
    >
      {children}
    </Table>
  );
};
