import { customFunctions } from "@/config/customFunction";
import DefaultInstance, { MasterInstance } from "@/instance/axios";
import { dataStyle, headerStyle } from "@/utils/excelDownLoad";
import { multiSheetExcelDownload } from "@/utils/multiSheetExcelDownload";
import {
  AbstractItemsGet200ResponseRowsInner,
  AuthSignupPost201ResponseEquipment,
  DefectsGet200ResponseRowsInner,
  DepartmentsGet200ResponseRowsInner,
  DowntimeReasonsGet200ResponseRowsInner,
  GradesGet200ResponseRowsInner,
  ItemCategoriesGet200ResponseRowsInner,
  ItemGradeViewGet200ResponseRowsInner,
  ItemsGet200ResponseRowsInner,
  ItemsGet200ResponseRowsInnerRoutingsInner,
  LocationsGet200ResponseRowsInner,
  MoldsGet200ResponseRowsInner,
  MoldStocksGet200ResponseRowsInner,
  OperationsGet200ResponseRowsInner,
  PartnersGet200ResponseRowsInner,
  RoutingOutsourcesGet200ResponseRowsInner,
  SitesGet200ResponseRowsInner,
} from "@sizlcorp/sizl-api-document/dist/models";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

export const useStandardMultiSheetExcelDownload = (setLoading: (state: boolean) => void) => {
  const toString = (value: any) => {
    return value ? value.toString() : "";
  };

  const { t } = useTranslation();
  const today = dayjs(new Date()).format("YYYYMMDD");

  // 🔥 청크 크기 설정
  const CHUNK_SIZE = 5;

  // 🔄 청크 처리 함수
  const chunkArray = (array: string | any[], size: number) => {
    return Array.from({ length: Math.ceil(array.length / size) }, (_, index) =>
      array.slice(index * size, index * size + size),
    );
  };

  const downloadExcel = async () => {
    try {
      setLoading(true);
      const apiRequests = [
        MasterInstance.itemsFindPost({}),
        MasterInstance.equipmentsFindPost({ populate: ["works", "location"] }),
        DefaultInstance.defectsFindPost({}),
        DefaultInstance.operationsFindPost({ populate: ["location"] }),
        DefaultInstance.routingsFindPost({ populate: ["item", "operation", "mold"] }),
        MasterInstance.sitesFindPost({}),
        MasterInstance.locationsFindPost({}),
        DefaultInstance.routingOutsourcesFindPost({ populate: ["location"] }),
        MasterInstance.moldsFindPost({}),
        MasterInstance.moldStocksFindPost({}),
        DefaultInstance.abstractItemsFindPost({}),
        MasterInstance.itemCategoriesFindPost({}),
        DefaultInstance.downtimeReasonsFindPost({}),
        ...(customFunctions.ADD_STANDARD_INFO_GRADE_MENU_SETTING
          ? [MasterInstance.gradesFindPost({})]
          : []),
        ...(customFunctions.ADD_STANDARD_INFO_DEPART_CODE_MENU_SETTING
          ? [MasterInstance.departmentsFindPost({})]
          : []),
        ...(customFunctions.ADD_STANDARD_INFO_PARTNER_MENU_SETTING
          ? [MasterInstance.partnersFindPost({})]
          : []),
        ...(customFunctions.ADD_STANDARD_INFO_BOM_SEARCH_MENU_SETTING
          ? [DefaultInstance.itemGradeViewFindPost({})]
          : []),
      ];

      const apiChunks = chunkArray(apiRequests, 5); // 청크 크기 설정
      const results = [];

      for (const chunk of apiChunks) {
        const responses = await Promise.allSettled(chunk); // 🔥 변경
        results.push(
          ...responses.map((result) => (result.status === "fulfilled" ? result.value : null)),
        );

        // 🔄 서버 과부하 방지: 500ms 지연 추가
        await new Promise((resolve) => setTimeout(resolve, 500));
      }

      const [
        items,
        equipments,
        defects,
        operations,
        routings,
        sites,
        locations,
        routingOutsources,
        molds,
        moldStocks,
        abstractItems,
        itemCategories,
        downtimeReasons,
        grades,
        departments,
        partners,
        bomSearch,
      ] = results;

      const sheetData = [
        {
          // 경남케미컬은 브랜치에서 따로 작업.
          sheetName: "품목",
          headerRow: [
            { v: t("item.name"), t: "s", s: headerStyle },
            { v: t("item.type"), t: "s", s: headerStyle },
            { v: t("item.code"), t: "s", s: headerStyle },
            { v: t("item.category"), t: "s", s: headerStyle },
            { v: t("abstractItem.code"), t: "s", s: headerStyle },
            { v: t("item.spec"), t: "s", s: headerStyle },
            { v: t("item.height"), t: "s", s: headerStyle },
            { v: t("item.depth"), t: "s", s: headerStyle },
            { v: t("item.width"), t: "s", s: headerStyle },
            { v: t("item.texture"), t: "s", s: headerStyle },
            { v: t("item.standardUph"), t: "s", s: headerStyle },
            { v: t("item.unit"), t: "s", s: headerStyle },
            { v: "로트 관리기준 (로트명)", t: "s", s: headerStyle },
            { v: "로트 관리기준 (유효기한)", t: "s", s: headerStyle },
          ],
          dataRows:
            items.data?.map((item: ItemsGet200ResponseRowsInner) => [
              { v: toString(item.name), t: "s", s: dataStyle },
              { v: toString(item.itemType), t: "s", s: dataStyle },
              { v: toString(item.code), t: "s", s: dataStyle },
              { v: toString(item.itemCategoryName), t: "s", s: dataStyle },
              { v: toString(item.abstractItemCode), t: "s", s: dataStyle },
              { v: toString(item.spec), t: "s", s: dataStyle },
              { v: toString(item.height), t: "s", s: dataStyle },
              { v: toString(item.depth), t: "s", s: dataStyle },
              { v: toString(item.width), t: "s", s: dataStyle },
              { v: toString(item.texture), t: "s", s: dataStyle },
              { v: toString(item.standardUph), t: "s", s: dataStyle },
              { v: toString(item.unitText), t: "s", s: dataStyle },
              { v: toString(item.usesLotName), t: "s", s: dataStyle },
              { v: toString(item.usesLotExpiration), t: "s", s: dataStyle },
            ]) ?? [],
        },
        {
          sheetName: "설비",
          headerRow: [
            { v: t("site.code"), t: "s", s: headerStyle },
            { v: t("equipment.code"), t: "s", s: headerStyle },
            { v: t("equipment.name"), t: "s", s: headerStyle },
            { v: t("equipment.type"), t: "s", s: headerStyle },
            { v: t("equipment.volt"), t: "s", s: headerStyle },
            { v: t("equipment.fromLocation"), t: "s", s: headerStyle },
            { v: t("equipment.toLocation"), t: "s", s: headerStyle },
            { v: t("equipment.targetLocation"), t: "s", s: headerStyle },
            ...(customFunctions.ADD_WORKER_ADD_FUNCTION
              ? [{ v: t("equipment.workerGroup"), t: "s", s: headerStyle }]
              : []),
            { v: t("equipment.downtimeReason"), t: "s", s: headerStyle },
          ],
          dataRows: equipments.data?.map((equipment: AuthSignupPost201ResponseEquipment) => [
            { v: toString(equipment.siteCode), t: "s", s: dataStyle },
            { v: toString(equipment.code), t: "s", s: dataStyle },
            { v: toString(equipment.name), t: "s", s: dataStyle },
            { v: toString(equipment.equipmentType), t: "s", s: dataStyle },
            { v: toString(equipment.volt), t: "s", s: dataStyle },
            { v: toString(equipment.fromLocationName), t: "s", s: dataStyle },
            { v: toString(equipment.toLocationName), t: "s", s: dataStyle },
            { v: toString(equipment.targetLocationName), t: "s", s: dataStyle },
            ...(customFunctions.ADD_WORKER_ADD_FUNCTION
              ? [{ v: toString(equipment.workerGroup?.join(", ")), t: "s", s: dataStyle }]
              : []),
            { v: toString(equipment.downtimeReasonCode), t: "s", s: dataStyle },
          ]),
        },
        {
          sheetName: "불량",
          headerRow: [
            { v: t("defect.code"), t: "s", s: headerStyle },
            { v: t("defect.name"), t: "s", s: headerStyle },
            { v: t("operation.code"), t: "s", s: headerStyle },
          ],
          dataRows: defects.data?.map((defect: DefectsGet200ResponseRowsInner) => [
            { v: toString(defect.code), t: "s", s: dataStyle },
            { v: toString(defect.name), t: "s", s: dataStyle },
            { v: toString(defect.operationCode), t: "s", s: dataStyle },
          ]),
        },
        {
          sheetName: "공정",
          headerRow: [
            { v: t("operation.code"), t: "s", s: headerStyle },
            { v: t("operation.name"), t: "s", s: headerStyle },
            { v: t("site.code"), t: "s", s: headerStyle },
            { v: t("operation.fromLocationName"), t: "s", s: headerStyle },
            { v: t("operation.toLocationName"), t: "s", s: headerStyle },
          ],
          dataRows: operations.data?.map((operation: OperationsGet200ResponseRowsInner) => [
            { v: toString(operation.code), t: "s", s: dataStyle },
            { v: toString(operation.name), t: "s", s: dataStyle },
            { v: toString(operation.siteCode), t: "s", s: dataStyle },
            { v: toString(operation.fromLocationName), t: "s", s: dataStyle },
            { v: toString(operation.toLocationName), t: "s", s: dataStyle },
          ]),
        },
        {
          sheetName: "라우팅",
          headerRow: [
            { v: t("routing.code"), t: "s", s: headerStyle },
            { v: t("item.name"), t: "s", s: headerStyle },
            { v: t("item.code"), t: "s", s: headerStyle },
            { v: t("item.spec"), t: "s", s: headerStyle },
            { v: t("operation.code"), t: "s", s: headerStyle },
            { v: t("routing.type"), t: "s", s: headerStyle },
            { v: t("mold.code"), t: "s", s: headerStyle },
            { v: t("routing.consumeType"), t: "s", s: headerStyle },
            { v: t("routing.seq"), t: "s", s: headerStyle },
          ],
          dataRows: routings.data?.map((routing: ItemsGet200ResponseRowsInnerRoutingsInner) => [
            { v: toString(routing.code), t: "s", s: dataStyle },
            { v: toString(routing.item?.name), t: "s", s: dataStyle },
            { v: toString(routing.itemCode), t: "s", s: dataStyle },
            { v: toString(routing.item?.spec), t: "s", s: dataStyle },
            { v: toString(routing.operationCode), t: "s", s: dataStyle },
            { v: t(toString(routing.routingType)), t: "s", s: dataStyle },
            { v: toString(routing.moldCode), t: "s", s: dataStyle },
            { v: t(toString(routing.consumeType)), t: "s", s: dataStyle },
            { v: toString(routing.seq), t: "s", s: dataStyle },
          ]),
        },
        {
          sheetName: "사업장",
          headerRow: [
            { v: t("site.code"), t: "s", s: headerStyle },
            { v: t("site.name"), t: "s", s: headerStyle },
          ],
          dataRows: sites.data?.map((site: SitesGet200ResponseRowsInner) => [
            { v: toString(site.code), t: "s", s: dataStyle },
            { v: toString(site.name), t: "s", s: dataStyle },
          ]),
        },
        {
          sheetName: "로케이션",
          headerRow: [
            { v: t("location.code"), t: "s", s: headerStyle },
            { v: t("location.name"), t: "s", s: headerStyle },
            { v: t("site.code"), t: "s", s: headerStyle },
            ...(customFunctions.ADD_DEFECT_LOCATION
              ? [{ v: t("location.isAvailable"), t: "s", s: headerStyle }]
              : []),
          ],
          dataRows: locations.data?.map((location: LocationsGet200ResponseRowsInner) => [
            { v: toString(location.code), t: "s", s: dataStyle },
            { v: toString(location.name), t: "s", s: dataStyle },
            { v: toString(location.siteCode), t: "s", s: dataStyle },
            ...(customFunctions.ADD_DEFECT_LOCATION
              ? [
                  {
                    v: location.isAvailable ? t("location.able") : t("location.disable"),
                    t: "s",
                    s: dataStyle,
                  },
                ]
              : []),
          ]),
        },
        {
          sheetName: "외주처",
          headerRow: [
            { v: t("outsource.name"), t: "s", s: headerStyle },
            { v: t("routing.code"), t: "s", s: headerStyle },
            { v: t("outsource.fromLocationName"), t: "s", s: headerStyle },
            { v: t("outsource.toLocationName"), t: "s", s: headerStyle },
          ],
          dataRows: routingOutsources.data?.map(
            (outsource: RoutingOutsourcesGet200ResponseRowsInner) => [
              { v: toString(outsource.name), t: "s", s: dataStyle },
              { v: toString(outsource.routingCode), t: "s", s: dataStyle },
              { v: toString(outsource.fromLocationName), t: "s", s: dataStyle },
              { v: toString(outsource.toLocationName), t: "s", s: dataStyle },
            ],
          ),
        },
        {
          sheetName: "금형",
          headerRow: [
            { v: t("mold.code"), t: "s", s: headerStyle },
            { v: t("site.code"), t: "s", s: headerStyle },
            { v: t("mold.capacity"), t: "s", s: headerStyle },
          ],
          dataRows: molds.data?.map((mold: MoldsGet200ResponseRowsInner) => [
            { v: toString(mold.code), t: "s", s: dataStyle },
            { v: toString(mold.siteCode), t: "s", s: dataStyle },
            { v: toString(mold.capacity), t: "s", s: dataStyle },
          ]),
        },
        {
          sheetName: "금형재고",
          headerRow: [
            { v: t("moldStock.code"), t: "s", s: headerStyle },
            { v: t("mold.code"), t: "s", s: headerStyle },
            { v: t("moldStock.limitCounter"), t: "s", s: headerStyle },
            { v: t("moldStock.inspectCounter"), t: "s", s: headerStyle },
            { v: t("moldStock.currentCounter"), t: "s", s: headerStyle },
          ],
          dataRows: moldStocks.data?.map((moldStock: MoldStocksGet200ResponseRowsInner) => [
            { v: toString(moldStock.code), t: "s", s: dataStyle },
            { v: toString(moldStock.moldCode), t: "s", s: dataStyle },
            { v: toString(moldStock.limitCounter), t: "s", s: dataStyle },
            { v: toString(moldStock.inspectCounter), t: "s", s: dataStyle },
            { v: toString(moldStock.currentCounter), t: "s", s: dataStyle },
          ]),
        },
        {
          sheetName: "품목군",
          headerRow: [{ v: t("abstractItem.code"), t: "s", s: headerStyle }],
          dataRows: abstractItems.data?.map(
            (abstractItem: AbstractItemsGet200ResponseRowsInner) => [
              { v: toString(abstractItem.code), t: "s", s: dataStyle },
            ],
          ),
        },
        {
          sheetName: "품목분류",
          headerRow: [{ v: t("category.name"), t: "s", s: headerStyle }],
          dataRows: itemCategories.data?.map((category: ItemCategoriesGet200ResponseRowsInner) => [
            { v: toString(category.name), t: "s", s: dataStyle },
          ]),
        },
        {
          sheetName: "비가동사유",
          headerRow: [
            { v: t("downtimeReason.seq"), t: "s", s: headerStyle },
            { v: t("downtimeReason.code"), t: "s", s: headerStyle },
            { v: t("downtimeReason.name"), t: "s", s: headerStyle },
            { v: t("operation.code"), t: "s", s: headerStyle },
          ],
          dataRows: downtimeReasons.data?.map((reason: DowntimeReasonsGet200ResponseRowsInner) => [
            { v: toString(reason.seq), t: "s", s: dataStyle },
            { v: toString(reason.code), t: "s", s: dataStyle },
            { v: toString(reason.name), t: "s", s: dataStyle },
            { v: toString(reason.operationCode), t: "s", s: dataStyle },
          ]),
        },
        ...(customFunctions.ADD_STANDARD_INFO_GRADE_MENU_SETTING
          ? [
              {
                sheetName: "등급",
                headerRow: [
                  { v: t("item.gradeCode"), t: "s", s: headerStyle },
                  { v: t("item.gradeName"), t: "s", s: headerStyle },
                ],
                dataRows: grades.data?.map((grade: GradesGet200ResponseRowsInner) => [
                  { v: toString(grade.code), t: "s", s: dataStyle },
                  { v: toString(grade.name), t: "s", s: dataStyle },
                ]),
              },
            ]
          : []),
        ...(customFunctions.ADD_STANDARD_INFO_DEPART_CODE_MENU_SETTING
          ? [
              {
                sheetName: "부서",
                headerRow: [
                  { v: t("department.code"), t: "s", s: headerStyle },
                  { v: t("department.name"), t: "s", s: headerStyle },
                ],
                dataRows: departments.data?.map(
                  (department: DepartmentsGet200ResponseRowsInner) => [
                    { v: toString(department.code), t: "s", s: dataStyle },
                    { v: toString(department.name), t: "s", s: dataStyle },
                  ],
                ),
              },
            ]
          : []),
        ...(customFunctions.ADD_STANDARD_INFO_PARTNER_MENU_SETTING
          ? [
              {
                sheetName: "협력사",
                headerRow: [
                  { v: t("partner.code"), t: "s", s: headerStyle },
                  { v: t("partner.name"), t: "s", s: headerStyle },
                  { v: t("partner.type"), t: "s", s: headerStyle },
                  { v: t("partner.contact"), t: "s", s: headerStyle },
                ],
                dataRows: partners.data?.map((partner: PartnersGet200ResponseRowsInner) => [
                  { v: toString(partner.code), t: "s", s: dataStyle },
                  { v: toString(partner.name), t: "s", s: dataStyle },
                  { v: t(toString(partner.type)), t: "s", s: dataStyle },
                  { v: toString(partner.contact), t: "s", s: dataStyle },
                ]),
              },
            ]
          : []),
        ...(customFunctions.ADD_STANDARD_INFO_BOM_MENU_SETTING
          ? [
              {
                sheetName: "BOM",
                headerRow: [
                  { v: t("item.name"), t: "s", s: headerStyle },
                  ...(customFunctions.ADD_STANDARD_INFO_GRADE_MENU_SETTING
                    ? [{ v: t("item.gradeName"), t: "s", s: headerStyle }]
                    : []),
                  { v: t("item.type"), t: "s", s: headerStyle },
                  { v: t("item.code"), t: "s", s: headerStyle },
                ],
                dataRows:
                  bomSearch.data?.map((item: Partial<ItemGradeViewGet200ResponseRowsInner>) => [
                    { v: toString(item.name), t: "s", s: dataStyle },
                    ...(customFunctions.ADD_STANDARD_INFO_GRADE_MENU_SETTING
                      ? [{ v: toString(item.gradeName), t: "s", s: dataStyle }]
                      : []),
                    { v: t(toString(item.itemType)), t: "s", s: dataStyle },
                    { v: toString(item.code), t: "s", s: dataStyle },
                  ]) ?? [],
              },
            ]
          : []),
      ];

      await multiSheetExcelDownload({
        sheets: sheetData,
        fileName: `${today}_기준정보`,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  return { downloadExcel };
};
