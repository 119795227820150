import { Modal, Portal } from "@mantine/core";
import React, { createContext, useContext, useState } from "react";
import Draggable from "react-draggable";

type ModalProps = {
  component: React.ReactNode; // 모달로 사용될 컴포넌트
  parameter: any; // 전달할 파라미터
  opened: boolean; // 모달이 열려있는지 여부
  title: string; // 모달의 타이틀
  onClose: (result?: any) => void; // 모달이 닫힐 때 실행할 콜백 함수
  closeOnClickOutside?: boolean; // 모달 바깥을 클릭했을 때 모달을 닫을지 여부
};

type ModalContextType = {
  openModal: (
    component: React.ReactNode,
    parameter: any,
    title: string,
    closeOnClickOutside?: boolean,
  ) => Promise<any>;
  closeModal: (result: any, index?: number) => void;
};

const ModalContext = createContext<ModalContextType | undefined>(undefined);

const ModalStackManager: React.FC<{ children: React.ReactNode; draggable?: boolean }> = ({
  children,
  draggable,
}) => {
  const [modalStack, setModalStack] = useState<ModalProps[]>([]);

  const openModal = (
    component: React.ReactNode,
    parameter: any,
    title: string,
    closeOnClickOutside?: boolean,
  ): Promise<unknown> => {
    // 같은 title을 가진 모달이 이미 열려있는지 확인합니다.
    if (modalStack.find((modal) => modal.title === title)) {
      // 이미 열려 있다면, 새로운 모달을 추가하지 않고 즉시 Promise를 resolve합니다.
      return Promise.resolve(null);
    }

    return new Promise<any>((resolve) => {
      const modalProps = {
        component,
        parameter,
        onClose: resolve,
        title,
        opened: true,
        closeOnClickOutside: closeOnClickOutside,
      };
      setModalStack((prevStack) => [...prevStack, modalProps]);
    });
  };

  const closeModal = (result?: any, index?: number) => {
    setModalStack((prevStack) => {
      if (index === undefined) {
        index = prevStack.length - 1;
      }
      const modal = prevStack[index];
      if (modal) {
        modal.onClose(result);
      }
      return prevStack.filter((_, i) => i !== index);
    });
  };

  return (
    <ModalContext.Provider value={{ openModal, closeModal }}>
      {children}
      <Portal>
        {modalStack.map((modalProps, index) => {
          const ModalComponent = (
            <Modal
              className="handle"
              styles={{
                inner: {
                  transform: draggable ? `translate(0, -1000%)` : "none",
                },
              }}
              opened={true}
              onClose={() => closeModal(null, index)}
              title={modalProps.title}
              size="auto"
              closeOnClickOutside={!modalProps.closeOnClickOutside}
            >
              {modalProps.component}
            </Modal>
          );

          return draggable ? (
            <Draggable
              handle=".handle" // 고유한 클래스명 사용
              grid={[25, 25]}
              defaultPosition={{ x: 0, y: 0 }}
            >
              {ModalComponent}
            </Draggable>
          ) : (
            <React.Fragment key={index}>{ModalComponent}</React.Fragment>
          );
        })}
      </Portal>
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  const modalContext = useContext(ModalContext);
  if (!modalContext) {
    throw new Error("useModal must be used within a ModalStackManager");
  }
  return modalContext;
};

export default ModalStackManager;
