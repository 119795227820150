import { customFunctions } from "@/config/customFunction";
import { useCheckboxContext } from "@/features/workByManagement/context/CheckboxProvider";
import { useWorkActions } from "@/features/workByManagement/hooks/useWorkActions";
import { useWorkState } from "@/features/workByManagement/hooks/useWorkState";
import { usePub, useSub } from "@/hooks";
import { theme } from "@/styles/theme";
import { Checkbox, Flex, Text, useMantineColorScheme } from "@mantine/core";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DefectTypeItem } from "../ui/DefectTypeSet";

interface WorkOperationTableHeadProps {
  defectTypes: DefectTypeItem[] | null;
  formReset?: boolean;
}
export const WorkOperationTableHead = ({ defectTypes, formReset }: WorkOperationTableHeadProps) => {
  const { areAllChecked, setCheckAll, checkedStates } = useCheckboxContext();
  const [allChecked, setAllChecked] = useState<boolean>(false);
  const publish = usePub();
  const state = useWorkState();
  const actions = useWorkActions();
  const { t } = useTranslation();
  const { colorScheme } = useMantineColorScheme();
  useEffect(() => {
    setAllChecked(state.works.length === state.tempWorks.length);
  }, [state.works, state.tempWorks]);

  useSub("check", () => {
    setAllChecked(state.works.length === state.tempWorks.length);
  });

  useSub("unCheck", () => {
    setAllChecked(false);
  });

  useEffect(() => {
    if (formReset) {
      setAllChecked(false);
    }
  }, [formReset, allChecked]);

  return (
    <thead>
      <tr>
        <th>
          <Flex justify="center">
            <Checkbox
              checked={allChecked}
              onChange={(e) => {
                if (e.currentTarget.checked) {
                  publish("checkAll");
                  actions.setWorks((prevWorks) => state.tempWorks);
                  setAllChecked(true);
                } else {
                  publish("unCheckAll");
                  actions.setWorks((prevWorks) => []);
                  setAllChecked(false);
                }
              }}
            />
          </Flex>
        </th>
        {/* <th>
          <Flex align="center" justify="center">
            <Checkbox
              checked={areAllChecked()}
              onChange={(e) => {
                const checkboxMessage =
                  Object.values(checkedStates).length === 0 && "선택 가능한 작업이 없습니다.";
                if (checkboxMessage) return alert(checkboxMessage);
                setCheckAll(e.target.checked);
              }}
            />
          </Flex>
        </th> */}
        <th>
          <Flex gap="md" justify="center" align="center" direction="column" wrap="wrap">
            <Text fz="sm" fw={500} ta="center" span>
              {t("work.workSequence")}
            </Text>
          </Flex>
        </th>
        <th>
          <Flex gap="md" justify="center" align="center" direction="column" wrap="wrap">
            <Text fz="sm" fw={500} ta="center" span>
              {t("work.lastWorkDate")}
            </Text>
            {customFunctions.ADD_OUTSOURCING_PRODUCTION_PLAN_BUTTON_AND_EXPECTED_DELIVERY_DATE && (
              <Text fz="sm" fw={500} ta="center" span>
                {t("workButton.delivery")}
              </Text>
            )}
          </Flex>
        </th>
        <th>
          <Flex gap="md" justify="center" align="center" direction="column" wrap="wrap">
            <Text fz="sm" fw={500} ta="center" span>
              {t("work.id")}
            </Text>
          </Flex>
        </th>
        <th>
          <Flex gap="md" justify="center" align="center" direction="column" wrap="wrap">
            <Text fz="sm" fw={500} ta="center" span>
              {t("work.outsourceCheck")}
            </Text>
          </Flex>
        </th>
        <th>
          <Flex gap="md" justify="center" align="center" direction="column" wrap="wrap">
            <Text fz="sm" fw={500} ta="center">
              {t("work.lineName")}
            </Text>
            <Text fz="sm" fw={500} ta="center">
              {t("equipment.name")}
            </Text>
          </Flex>
        </th>
        <th>
          <Flex align="center" direction="column" wrap="wrap">
            <Flex direction="column" align="flex-start" gap="sm" wrap="wrap">
              <Text fz="sm" fw={500}>
                {t("work.aiItem")} ({t("routing.code")})
              </Text>
              <Text fz="sm" fw={500}>
                {t("item.name")} ({t("item.code")})
              </Text>
              <Text fz="sm" fw={500} ta="center">
                {t("item.spec")}
              </Text>
              <Text fz="sm" fw={500} ta="center">
                {t("work.progress")}
              </Text>
            </Flex>
          </Flex>
        </th>
        <th>
          <Flex align="center" direction="column" wrap="wrap">
            <Flex direction="column" align="flex-start" gap="sm" wrap="wrap">
              <Flex gap="xs">
                <Text fz="sm" fw={500} c={theme.colors?.blue?.[9]}>
                  {t("work.targetQuantity")}
                </Text>
                <Text>/</Text>
                <Text fz="sm" fw={500} c={theme.colors?.orange?.[9]}>
                  {t("work.remainQuantity")}
                </Text>
              </Flex>
              <Flex gap="xs">
                <Text fz="sm" fw={500} c={theme.colors?.teal?.[9]} ta="center">
                  {t("work.totalQuantity")}
                </Text>
                <Text>/</Text>
                <Text fz="sm" fw={500} c={theme.colors?.red?.[9]} ta="center">
                  {t("work.defectQuantity")}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </th>
        <th>
          <Text fz="sm" fw={500} ta="center">
            {t("work.completedQuantity")}
          </Text>
        </th>
        {defectTypes?.map((defect) => (
          <th key={defect.value}>
            <Text fz="sm" fw={500} ta="center">
              {defect.label}
            </Text>
          </th>
        ))}
      </tr>
    </thead>
  );
};
