import { users } from "@/api/users/useUsersQuery";
import { customFunctions } from "@/config/customFunction";
import { useModal } from "@/features/modal/ModalStackManager";
import { FileImportForm } from "@/features/ui/form/file";
import { useLoader } from "@/hooks/useLoader";
import { useStandardMultiSheetExcelDownload } from "@/hooks/useStandardMultiSheetExcelDownload";
import { Flex, Menu, ScrollArea, Text } from "@mantine/core";
import { useResizeObserver } from "@mantine/hooks";
import { AuthSignupPost201ResponseExternalUserTypeEnum } from "@sizlcorp/sizl-api-document/dist/models";
import { IconChevronDown, IconChevronRight } from "@tabler/icons-react";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

type LinkType = {
  label: string;
  links?: (Link | NestedLink)[];
  link?: string;
  visible?: boolean; // 추가
  target?: string;
  code?: string;
}[];

type Link = {
  link: string;
  label: string;
  action?: boolean;
  actionKey?: "download" | "upload" | "forward" | "reverce";
  visible?: boolean; // 추가
  target?: string;
  code?: string;
  subCode?: string;
};

type NestedLink = {
  label: string;
  links: (Link | NestedLink)[];
  action?: boolean;
  actionKey?: "download" | "upload" | "forward" | "reverce";
  visible?: boolean; // 추가
  target?: string;
};

// 메뉴 Item의 최대 높이 설정. 높이가 MAX_HEIGHT보다 크면 스크롤바를 표시하기 위함
const MAX_HEIGHT = 400; // 25rem

const RenderSubMenuItems = ({ items, label }: { items: (Link | NestedLink)[]; label?: string }) => {
  const { t } = useTranslation();
  const { openModal } = useModal();
  const { loading, setLoading, LoadingOverlay } = useLoader();
  const { downloadExcel } = useStandardMultiSheetExcelDownload(setLoading);

  const handleDownLoad = async () => await downloadExcel();
  const handleUpload = () => openModal(<FileImportForm />, null, "기준정보 업로드");

  if (!items || items.length === 0) return null;

  return (
    <>
      {loading && <LoadingOverlay />}
      {items
        .filter((item) => !!item.visible)
        .map((item) => {
          if ("links" in item) {
            return (
              <Menu.Item component="div" key={item.label}>
                <Flex justify="space-between" align="center">
                  <Menu shadow="md" trigger="hover" position="right" withArrow withinPortal>
                    <Menu.Target>
                      <Flex
                        justify="space-between"
                        align="center"
                        style={{ cursor: "pointer", width: "100%" }}
                      >
                        <Text>{t(item.label)}</Text>
                        <IconChevronRight size="0.9rem" stroke={1.5} />
                      </Flex>
                    </Menu.Target>
                    <Menu.Dropdown>
                      <RenderSubMenuItems items={item.links} label={item.label} />
                    </Menu.Dropdown>
                  </Menu>
                </Flex>
              </Menu.Item>
            );
          }

          return (
            <Menu.Item
              key={item.label}
              component={RouterLink}
              to={item.action ? "#" : `${item.link}?label=${item.code}&group=${label}`}
              target={item.target}
              onClick={
                item.action
                  ? item.actionKey === "download"
                    ? handleDownLoad
                    : item.actionKey === "upload"
                      ? handleUpload
                      : undefined
                  : undefined
              }
              color={item.action ? "indigo" : ""}
            >
              {t(item.label)}
            </Menu.Item>
          );
        })}
    </>
  );
};

const renderMainMenuItems = (items: LinkType, t: (key: string) => string) => {
  return items
    .filter((item) => !!item.visible)
    .map((item) => {
      const hasLinks = item.links && item.links.length > 0;

      return (
        <Menu key={item.label} shadow="md" trigger="hover" withArrow withinPortal>
          <Menu.Target>
            {item.link ? (
              <RouterLink
                to={`${item.link}?label=${item.label}`}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <Flex align="center" style={{ cursor: "pointer" }}>
                  <Text>{t(item.label)}</Text>
                </Flex>
              </RouterLink>
            ) : (
              <Flex align="center" style={{ cursor: "pointer" }}>
                <Text>{t(item.label)}</Text>
                {hasLinks && <IconChevronDown size="0.9rem" stroke={1.5} />}
              </Flex>
            )}
          </Menu.Target>
          <Menu.Dropdown>
            <DynamicHeightScrollArea>
              <RenderSubMenuItems items={item.links ?? []} label={item.label} />
            </DynamicHeightScrollArea>
          </Menu.Dropdown>
        </Menu>
      );
    });
};

const DynamicHeightScrollArea = ({ children }: { children: React.ReactNode }) => {
  const [ref, rect] = useResizeObserver();
  const calculatedHeight = rect.height > MAX_HEIGHT ? MAX_HEIGHT : rect.height;

  return (
    <ScrollArea h={calculatedHeight}>
      <Flex direction="column" ref={ref}>
        {children || <div>No children provided</div>}
      </Flex>
    </ScrollArea>
  );
};

export const MenuTemplate = () => {
  const { t, i18n } = useTranslation();
  const { data } = useQuery({ ...users.who({}) });
  const userType = data?.data?.externalUserType as AuthSignupPost201ResponseExternalUserTypeEnum;
  const partnerType = data?.data?.partner?.type;

  useEffect(() => {
    if (userType) {
      localStorage.setItem("userType", userType as AuthSignupPost201ResponseExternalUserTypeEnum);
    } else if (!userType) {
      localStorage.removeItem("userType");
    }
  }, [userType]);

  const links: LinkType = [
    { link: "/dashboard", label: t("menu.dashboard"), visible: true, code: "menu.dashboard" },
    {
      label: "테스트",
      visible: customFunctions.ADD_BEST_PRACTICE_TEST_MENU_SETTING,
      links: [
        {
          link: "/test-standard-info-best-practice",
          label: t("menu.standard.standard"),
          visible: true,
          code: "menu.standard.standard",
        },
      ],
    },
    {
      label: t("menu.system.management"),
      visible: customFunctions.ADD_SYSTEM_MANAGEMENT_MENU_SETTING,
      links: [
        { link: "/system", label: "System Access Log", visible: true, code: "System Access Log" },
        {
          link: "/system-logs",
          label: t("menu.system.log"),
          visible: true,
          code: "menu.system.log",
        },
        {
          link: "/system-parameter",
          label: t("menu.system.parameter"),
          visible: true,
          code: "menu.system.parameter",
        },
      ],
    },
    {
      label: t("menu.standard.standard"),
      visible: true,
      links: [
        {
          link: "/common-code",
          label: t("menu.standard.commonCode"),
          visible: customFunctions.ADD_STANDARD_INFO_COMMON_CODE_MENU_SETTING,
          code: "menu.standard.commonCode",
        },
        {
          link: "/authority",
          label: t("menu.standard.authority"),
          visible: customFunctions.ADD_STANDARD_INFO_AUTH_GROUP_MENU_SETTING,
          code: "menu.standard.authority",
        },
        {
          link: "/department",
          label: t("menu.standard.department"),
          visible: customFunctions.ADD_STANDARD_INFO_DEPART_CODE_MENU_SETTING,
          code: "menu.standard.department",
        },
        { link: "/items", label: t("item.item"), visible: true, code: "item.item" },
        {
          link: "/equipment",
          label: t("equipment.equipment"),
          visible: true,
          code: "equipment.equipment",
        },
        { link: "/defects", label: t("defect.defect"), visible: true, code: "defect.defect" },
        {
          link: "/operations",
          label: t("operation.operation"),
          visible: true,
          code: "operation.operation",
        },
        { link: "/routing", label: t("routing.routing"), visible: true, code: "routing.routing" },
        { link: "/users", label: t("user.user"), visible: true, code: "user.user" },
        {
          link: "/userLogs",
          label: t("menu.standard.userLogs"),
          visible: customFunctions.ADD_USER_ACCESS_STATUS,
          code: "menu.standard.userLogs",
        },
        { link: "/site", label: t("site.site"), visible: true, code: "site.site" },
        {
          link: "/location",
          label: t("location.location"),
          visible: true,
          code: "location.location",
        },
        {
          link: "/routing-outsource",
          label: t("outsource.outsource"),
          visible: true,
          code: "outsource.outsource",
        },
        {
          link: "/ALCmodel",
          label: t("menu.standard.ALCmodel"),
          visible: customFunctions.ADD_STANDARD_INFO_ALC_MODEL_MENU_SETTING,
          code: "menu.standard.ALCmodel",
        },
        { link: "/mold", label: t("mold.mold"), visible: true, code: "mold.mold" },
        {
          link: "/moldStock",
          label: t("moldStock.moldStock"),
          visible: true,
          code: "moldStock.moldStock",
        },
        {
          link: "/abstract-item",
          label: t("abstractItem.abstractItem"),
          visible: true,
          code: "abstractItem.abstractItem",
        },
        {
          link: "/item-category",
          label: t("category.category"),
          visible: true,
          code: "category.category",
        },
        {
          link: "/partner",
          label: t("partner.partner"),
          visible: customFunctions.ADD_STANDARD_INFO_PARTNER_MENU_SETTING,
          code: "partner.partner",
        },
        {
          link: "/downtimeReasons",
          label: t("downtimeReason.downtimeReason"),
          visible: true,
          code: "downtimeReason.downtimeReason",
        },
        {
          link: "/bom",
          label: t("menu.standard.bom"),
          visible: customFunctions.ADD_STANDARD_INFO_BOM_MENU_SETTING,
          code: "menu.standard.bom",
        },
        {
          link: "/bomSearch",
          label: t("menu.standard.bomSearch"),
          visible: customFunctions.ADD_STANDARD_INFO_BOM_SEARCH_MENU_SETTING,
          code: "menu.standard.bomSearch",
        },
        {
          link: "/bomList",
          label: t("menu.standard.bomList"),
          visible: customFunctions.ADD_STANDARD_INFO_BOM_LIST_MENU_SETTING,
          code: "menu.standard.bomList",
        },
        {
          link: "/document-file",
          label: t("menu.standard.file"),
          visible: customFunctions.ADD_STANDARD_INFO_FILE_MENU_SETTING,
          code: "menu.standard.file",
        },
        {
          link: "/peripheral",
          label: t("menu.standard.peripheral"),
          visible: customFunctions.ADD_STANDARD_INFO_PERIPHERAL_MENU_SETTING,
          code: "menu.standard.peripheral",
        },
        {
          link: "/machine",
          label: t("menu.standard.machine"),
          visible: customFunctions.ADD_STANDARD_INFO_MACHINE_MENU_SETTING,
          code: "menu.standard.machine",
        },
        {
          link: "/grade",
          label: "등급",
          visible: customFunctions.ADD_STANDARD_INFO_GRADE_MENU_SETTING,
          code: "등급",
        },
        {
          link: "/#",
          label: t("menu.standard.download"),
          action: true,
          actionKey: "download",
          visible: true,
          code: "menu.standard.download",
        },
        {
          link: "/#",
          label: t("menu.standard.upload"),
          action: true,
          actionKey: "upload",
          visible: true,
          code: "menu.standard.upload",
        },
      ],
    },
    {
      label: t("menu.production.production"),
      visible: true,
      links: [
        {
          link: "/work-by-equipment",
          label: t("menu.production.equipment"),
          visible: true,
          code: "menu.production.equipment",
        },
        {
          link: "/work-by-production-plan",
          label: t("menu.production.productionPlan"),
          visible: true,
          code: "menu.production.productionPlan",
        },
        {
          link: "/work-by-row",
          label: t("menu.production.work"),
          visible: true,
          code: "menu.production.work",
        },
        {
          link: "/operation-outsource-work-by-row",
          label: t("menu.production.outsource"),
          visible: customFunctions.ADD_SHOW_OPERATION_OUTSOURCING_WORK,
          code: "menu.production.outsource",
        },
        {
          link: "/work-log",
          label: t("menu.production.workLog"),
          visible: true,
          code: "menu.production.workLog",
        },
        {
          link: "/productionperformance",
          label: t("menu.production.performance"),
          visible: customFunctions.ADD_PERFORMANCE_MENU_SETTING,
          code: "menu.production.performance",
        },
        {
          link: "/productionperformanceOutsourcing",
          label: t("menu.production.outsourcePerformance"),
          visible: customFunctions.ADD_PERFORMANCE_OUTSOURCING_MENU_SETTING,
          code: "menu.production.outsourcePerformance",
        },
        // { link: "/downtimeReasons-log", label: "정지유형별 통계", visible: customFunctions.ADD_DOWNTIME_REASON_LOG_MENU_SETTING },
        {
          link: "/lotForward",
          label: t("menu.production.lotForward"),
          visible: true,
          code: "menu.production.lotForward",
        },
        {
          link: "/lotReverce",
          label: t("menu.production.lotReverce"),
          visible: true,
          code: "menu.production.lotReverce",
        },
      ],
    },
    {
      label: t("menu.inventory.management"),
      visible: true,
      links: [
        {
          link: "/inventory",
          label: t("menu.inventory.inventory"),
          visible: true,
          code: "menu.inventory.inventory",
        },
        {
          link: "/ledgersAll",
          label: t("menu.inventory.ledger"),
          visible: true,
          code: "menu.inventory.ledger",
        },
        {
          link: "/wipInventory",
          label: t("menu.inventory.wipInventory"),
          visible: true,
          code: "menu.inventory.wipInventory",
        },
        {
          link: "/wmsLogs",
          label: t("menu.inventory.wmsLog"),
          visible: true,
          code: "menu.inventory.wmsLog",
        },
        {
          link: "/lackSafety",
          label: t("menu.inventory.lackSafety"),
          visible: customFunctions.ADD_LACK_SAFETY_MENU_SETTING,
          code: "menu.inventory.lackSafety",
        },
        {
          link: "/inventoryLife",
          label: t("menu.inventory.life"),
          visible: customFunctions.ADD_INVENTORY_LIFE_MENU_SETTING,
          code: "menu.inventory.life",
        },
        {
          link: "/lots",
          label: t("menu.inventory.lot"),
          visible: customFunctions.ADD_LOT_MANAGEMENT,
          code: "menu.inventory.lot",
        },
      ],
    },
    {
      label: t("menu.shipment.shipment"),
      visible: customFunctions.ADD_STORAGE_MANAGEMENT_MENU_SETTING,
      links: [
        {
          link: "/shipment-request",
          label: t("menu.shipment.request"),
          visible: true,
          code: "menu.shipment.request",
        },
        {
          link: "/shipment-receipt",
          label: t("menu.shipment.receipt"),
          visible: true,
          code: "menu.shipment.receipt",
        },
        {
          link: "/shipment-status",
          label: t("menu.shipment.status"),
          visible: true,
          code: "menu.shipment.status",
        },
      ],
    },
    {
      label: t("menu.sales.sales"),
      visible: true,
      links: [
        {
          link: "/purchaseOrder",
          label: t("menu.sales.purchaseOrder"),
          visible: !customFunctions.ADD_PURCHASE_ORDER_ITEMS_MENU_SETTING,
          code: "menu.sales.purchaseOrder",
        },
        {
          link: "/purchaseOrderItems",
          label: t("purchaseOrderItems.purchaseOrderItems"),
          visible: !customFunctions.ADD_PURCHASE_ORDER_ITEMS_MENU_SETTING,
          code: "purchaseOrderItems.purchaseOrderItems",
          subCode: "orderItems.description",
        },
        {
          link: "invoiceStatement",
          label: t("invoice.invoice"),
          visible: customFunctions.ADD_INVOICE_STATEMENT_MENU_SETTING,
          code: "invoice.invoice",
          subCode: "invoice.subTitle",
        },
        {
          link: "/invoiceStatementComplete",
          label: t("invoice.invoiceComplete"),
          visible: customFunctions.ADD_INVOICE_STATEMENT_MENU_SETTING,
          code: "invoice.invoiceComplete",
        },
        {
          link: "/deliveryStatus",
          label: t("menu.sales.delivery"),
          visible: true,
          code: "menu.sales.delivery",
        },
        {
          link: "/order",
          label: t("menu.sales.order"),
          visible: true,
          code: "menu.sales.order",
        },
        {
          link: "/orderItems",
          label: t("orderItems.orderItems"),
          visible: true,
          code: "orderItems.orderItems",
          subCode: "orderItems.description",
        },
        {
          link: "/orderComplete",
          label: t("order.orderComplete"),
          visible: customFunctions.ADD_ORDER_COMPLETE_MENU_SETTING,
          code: "order.orderComplete",
        },
        {
          link: "/stockPlan",
          label: t("stockPlan.stockPlan"),
          visible: true,
          code: "stockPlan.stockPlan",
        },
        {
          link: "/transportation",
          label: t("transportation.transportation"),
          visible: true,
          code: "transportation.transportation",
          subCode: "transportation.transportation",
        },
      ],
    },
    {
      label: t("menu.quality.quality"),
      visible: customFunctions.ADD_QUALITY_MENU_SETTING,
      links: [
        {
          link: "/inspectSpecification",
          label: t("menu.quality.inspect"),
          visible: true,
          code: "menu.quality.inspect",
        },
        {
          link: "/inspectionRecords",
          label: t("menu.quality.inspectRecord"),
          visible: true,
          code: "menu.quality.inspectRecord",
        },
        {
          link: "/quality/defect-statistics",
          label: t("menu.quality.defect"),
          visible: customFunctions.ADD_DEFECT_STATISTICS_MENU_SETTING,
          code: "menu.quality.defect",
        },
        {
          link: "/quality/work-standardList",
          label: t("menu.quality.work"),
          visible: customFunctions.ADD_WORK_STANDARD_DOCUMENT_LIST_MENU_SETTING,
          code: "menu.quality.work",
        },
        // { link: "/quality/change-register", label: "변경점 정보 등록" },
        {
          link: "/quality/change-list",
          label: t("menu.quality.change"),
          visible: customFunctions.ADD_CHANGE_LIST_MENU_SETTING,
          code: "menu.quality.change",
        },
        // { link: "/quality/pre-warehouseList", label: "수입검사", visible: customFunctions.ADD_PRE_WAREHOUSE_LIST_MENU_SETTING },
      ],
    },
    {
      label: "KPI",
      visible: customFunctions.ADD_KPI_MENU_SETTING,
      links: [
        {
          link: "/kpi/leadtime",
          label: t("menu.kpi.leadtime"),
          visible:
            customFunctions.ADD_KPI_MENU_SETTING && !customFunctions.ADD_BTTELECOM_KPI_MENU_SETTING,
          code: "menu.kpi.leadtime",
        },
        {
          link: "/kpi/manhour",
          label: t("menu.kpi.manhour"),
          visible:
            customFunctions.ADD_KPI_MENU_SETTING && !customFunctions.ADD_BTTELECOM_KPI_MENU_SETTING,
          code: "menu.kpi.manhour",
        },
        {
          link: "/kpi/defect",
          label: t("menu.kpi.defect"),
          visible: customFunctions.ADD_KPI_MENU_SETTING,
          code: "menu.kpi.manhour",
        },
        {
          link: "/kpi/leadtimeOrder",
          label: t("menu.kpi.leadtimeOrder"),
          visible:
            customFunctions.ADD_KPI_MENU_SETTING && !customFunctions.ADD_BTTELECOM_KPI_MENU_SETTING,
          code: "menu.kpi.leadtimeOrder",
        },
        {
          link: "/kpi/powerUsage",
          label: t("menu.kpi.powerUsage"),
          visible:
            customFunctions.ADD_KPI_MENU_SETTING && !customFunctions.ADD_BTTELECOM_KPI_MENU_SETTING,
          code: "menu.kpi.powerUsage",
        },
        {
          link: "/kpi/uph",
          label: t("menu.kpi.uph"),
          visible:
            customFunctions.ADD_KPI_MENU_SETTING && !customFunctions.ADD_BTTELECOM_KPI_MENU_SETTING,
          code: "menu.kpi.uph",
        },
        {
          link: "/kpi/rateOfOperation",
          label: t("menu.kpi.rateOperation"),
          visible: customFunctions.ADD_KPI_MENU_SETTING,
          code: "menu.kpi.rateOperation",
        },
        {
          link: "/dataDisplay",
          label: t("menu.kpi.dataDisplay"),
          visible: customFunctions.ADD_DATA_DISPLAY_MENU_SETTING,
          code: "menu.kpi.dataDisplay",
        },
      ],
    },
    {
      label: t("menu.monitoring.monitoring"),
      visible: customFunctions.ADD_MONITORING_MENU_SETTING,
      links: [
        {
          link: "/overview",
          label: t("menu.monitoring.mes"),
          visible: true,
          code: "menu.monitoring.mes",
        },
        {
          link: "/monitoring",
          label: t("menu.monitoring.monitoring"),
          visible: true,
          code: "menu.monitoring.monitoring",
        },
        {
          link: "/equipmentAnalysis",
          label: t("menu.monitoring.equipment"),
          visible: customFunctions.ADD_DASHBOARD_EQUIPMENT_ANALYSIS,
          code: "menu.monitoring.equipment",
        },
        {
          link: "/pressMonitoring/factoryMonitoring",
          label: t("menu.monitoring.pms"),
          visible: customFunctions.ADD_PMS_PRESS_MONITORING,
          code: "menu.monitoring.pms",
        },
      ],
    },
    {
      label: "PMS",
      visible: customFunctions.ADD_PMS_MENU_SETTING,
      links: [
        {
          link: "/monitoring",
          label: t("menu.pms.monitoring"),
          visible: true,
          code: "menu.pms.monitoring",
        },
        {
          link: "/pressMonitoring/factoryMonitoring",
          label: t("menu.pms.analysisMonitoring"),
          visible: true,
          code: "menu.pms.analysisMonitoring",
        },
        {
          link: "/press",
          label: t("menu.pms.press.management"),
          links: [
            {
              link: "/press/errorview",
              label: t("menu.pms.press.error"),
              visible: true,
              code: "menu.pms.press.error",
            },
            // { link: '/press/parameterview', label: '파라미터 보기', visible: true },
            // { link: '/press/camview', label: '캠 보기', visible: true },
            // { link: '/press/clutchandbrake', label: '클러치 & 브레이크', visible: true },
            {
              link: "/press/facilities",
              label: t("menu.pms.press.repair"),
              visible: true,
              code: "menu.pms.press.repair",
            },
            {
              link: "/press/complete/admin",
              label: t("menu.pms.press.repairComplete"),
              visible: true,
              code: "menu.pms.press.repairComplete",
            },
            {
              link: "/press/problem",
              label: t("menu.pms.press.problem"),
              visible: true,
              code: "menu.pms.press.problem",
            },
            {
              link: "/press/PressDailyRegister",
              label: t("menu.pms.press.dailyRegister"),
              visible: true,
              code: "menu.pms.press.dailyRegister",
            },
            {
              link: "/press/PressDailyStatus",
              label: t("menu.pms.press.dailyStatus"),
              visible: true,
              code: "menu.pms.press.dailyStatus",
            },
          ],
          visible: true,
        },
        {
          link: "/analysis",
          label: t("menu.pms.analysis.analysis"),
          links: [
            {
              link: "/analysis/output",
              label: t("menu.pms.analysis.output"),
              visible: true,
              code: "menu.pms.analysis.output",
            },
            {
              link: "/analysis/ability",
              label: t("menu.pms.analysis.ability"),
              visible: true,
              code: "menu.pms.analysis.ability",
            },
            // { link: '/analysis/totaloutput', label: '총생산량', visible: true },
            {
              link: "/analysis/error",
              label: t("menu.pms.analysis.error"),
              visible: true,
              code: "menu.pms.analysis.error",
            },
            {
              link: "/analysis/power",
              label: t("menu.pms.analysis.power"),
              visible: true,
              code: "menu.pms.analysis.power",
            },
            {
              link: "/analysis/idleTime",
              label: t("menu.pms.analysis.idleTime"),
              visible: true,
              code: "menu.pms.analysis.idleTime",
            },
            {
              link: "/analysis/workTime",
              label: t("menu.pms.analysis.workTime"),
              visible: true,
              code: "menu.pms.analysis.workTime",
            },
          ],
          visible: true,
        },
        {
          link: "/moldManagement",
          label: t("menu.pms.mold.management"),
          links: [
            {
              link: "/mold/maintenance",
              label: t("menu.pms.mold.maintenance"),
              visible: true,
              code: "menu.pms.mold.maintenance",
            },
            {
              link: "/mold/repairs",
              label: t("menu.pms.mold.repair"),
              visible: true,
              code: "menu.pms.mold.repair",
            },
            {
              link: "/mold/complete/admin",
              label: t("menu.pms.mold.repairComplete"),
              visible: true,
              code: "menu.pms.mold.repairComplete",
            },
            {
              link: "/mold/problem",
              label: t("menu.pms.mold.problem"),
              visible: true,
              code: "menu.pms.mold.problem",
            },
            {
              link: "/mold/MoldDailyRegister",
              label: t("menu.pms.mold.dailyRegister"),
              visible: true,
              code: "menu.pms.mold.dailyRegister",
            },
            {
              link: "/mold/MoldDailyStatus",
              label: t("menu.pms.mold.dailyStatus"),
              visible: true,
              code: "menu.pms.mold.dailyStatus",
            },
          ],
          visible: customFunctions.ADD_EQUIPMENT_MOLD_MENU_SETTING,
        },
      ],
    },
    {
      label: t("menu.pms.monitoring"),
      visible: customFunctions.ADD_EQUIPMENT_MONITORING_MENU_SETTING,
      links: [
        {
          label: t("menu.pms.monitoring"),
          links: [
            {
              link: "/overview",
              label: t("menu.monitoring.equipment"),
              visible: true,
              code: "menu.monitoring.equipment",
            },
            {
              link: "/monitoring",
              label: t("menu.monitoring.monitoring"),
              visible: true,
              code: "menu.monitoring.monitoring",
            },
            {
              link: "/analysisMonitoring",
              label: t("menu.equipment.analysisMonitoring"),
              visible: true,
              code: "menu.equipment.analysisMonitoring",
            },
          ],
          visible: true,
        },
        {
          label: t("menu.equipment.management.management"),
          links: [
            {
              link: "/errorView",
              label: t("menu.equipment.management.error"),
              visible: true,
              code: "menu.equipment.management.error",
            },
            {
              link: "/maintenance",
              label: t("menu.equipment.management.repair"),
              visible: true,
              code: "menu.equipment.management.repair",
            },
            {
              link: "/maintenanceList",
              label: t("menu.equipment.management.repairList"),
              visible: true,
              code: "menu.equipment.management.repairList",
            },
            {
              link: "/maintenanceComplete",
              label: t("menu.equipment.management.repairComplete"),
              visible: true,
              code: "menu.equipment.management.repairComplete",
            },
            {
              link: "/equipmentProblem",
              label: t("menu.equipment.management.problem"),
              visible: true,
              code: "menu.equipment.management.problem",
            },
            {
              link: "/dailyRegister",
              label: t("menu.equipment.management.dailyRegister"),
              visible: true,
              code: "menu.equipment.management.dailyRegister",
            },
            {
              link: "/dailyList",
              label: t("menu.equipment.management.dailyStatus"),
              visible: true,
              code: "menu.equipment.management.dailyStatus",
            },
          ],
          visible: true,
        },
        {
          label: t("menu.equipment.statistical"),
          links: [
            {
              link: "/output",
              label: t("menu.pms.analysis.output"),
              visible: true,
              code: "menu.pms.analysis.output",
            },
            {
              link: "/error",
              label: t("menu.pms.analysis.error"),
              visible: true,
              code: "menu.pms.analysis.error",
            },
            {
              link: "/power",
              label: t("menu.pms.analysis.power"),
              visible: true,
              code: "menu.pms.analysis.power",
            },
            {
              link: "/idleTime",
              label: t("menu.pms.analysis.idleTime"),
              visible: true,
              code: "menu.pms.analysis.idleTime",
            },
            {
              link: "/workTime",
              label: t("menu.pms.analysis.workTime"),
              visible: true,
              code: "menu.pms.analysis.workTime",
            },
          ],
          visible: true,
        },
      ],
    },
    {
      label: "SCM",
      visible:
        customFunctions.ADD_SCM_MENU_SETTING &&
        (userType === AuthSignupPost201ResponseExternalUserTypeEnum.SALES ||
          userType === AuthSignupPost201ResponseExternalUserTypeEnum.PURCHASE),
      links: [
        {
          label: t("menu.scm.client.sales"),
          links: [
            {
              link: "/purchaseOrder",
              label: t("menu.scm.client.purchaseOrder"),
              visible: true,
              code: "menu.scm.client.purchaseOrder",
            },
            {
              link: "/purchaseOrderItems",
              label: t("menu.scm.client.purchaseOrderItem"),
              visible: true,
              code: "menu.scm.client.purchaseOrderItem",
            },
            // { link: "/purchaseOrderItems-gyeongnam", label: "수주현황 (경남)", visible: true },
            // { link: "/deliveryStatus-gyeongnam", label: "납품정보등록 (경남)", visible: true },
            {
              link: "/deliveryStatus",
              label: t("menu.scm.client.delivery"),
              visible: true,
              code: "menu.scm.client.delivery",
            },
            // { link: "/deliveryList-gyeongnam", label: "납품현황 (경남)", visible: true },
          ],
          visible: userType === "SALES" ? true : false,
        },
        {
          label: t("menu.scm.partner.sales"),
          links: [
            // { link: "/order-gyeongnam", label: "발주등록 (경남)", visible: true },
            {
              link: "/order",
              label: t("menu.scm.partner.order"),
              visible: true,
              code: "menu.scm.partner.order",
            },
            {
              link: "/orderItems",
              label: t("menu.scm.partner.orderItems"),
              visible: true,
              code: "menu.scm.partner.orderItems",
            },
            // { link: "/deliveryStatus-supplier", label: "납품정보등록 (협력사)", visible: true },
            // { link: "/deliveryList-gyeongnam", label: "납품현황 (경남)", visible: true },
            {
              link: "/deliveryStatus",
              label: t("menu.scm.partner.delivery"),
              visible: true,
              code: "menu.scm.partner.delivery",
            },
          ],
          visible:
            userType === AuthSignupPost201ResponseExternalUserTypeEnum.PURCHASE ? true : false,
        },
        {
          label: t("menu.scm.partner.receiving"),
          links: [
            {
              link: "/shipment-request-supplier",
              label: t("menu.scm.partner.receivingRequest"),
              visible: true,
              code: "menu.scm.partner.receivingRequest",
            },
            {
              link: "/warehousingRegister",
              label: t("menu.scm.partner.receivingReceipt"),
              visible: true,
              code: "menu.scm.partner.receivingReceipt",
            },
            {
              link: "/shipment-status-supplier",
              label: t("menu.scm.partner.receivingStatus"),
              visible: true,
              code: "menu.scm.partner.receivingStatus",
            },
          ],
          visible:
            userType === AuthSignupPost201ResponseExternalUserTypeEnum.PURCHASE ? true : false,
        },
      ],
    },
    {
      label: t("menu.gps.gps"),
      visible: customFunctions.ADD_GPS_SYSTEM_MENU_SETTING,
      links: [
        {
          link: "http://taekang-tracker.sizl.co.kr/",
          label: t("menu.gps.tracking"),
          visible: true,
          target: "_blank",
          code: "menu.gps.tracking",
        },
      ],
    },
  ];

  const [filteredLinks, setFilteredLinks] = useState<LinkType>(links);

  useEffect(() => {
    updateLinks();
  }, [data?.data?.externalUserType, i18n.language, t]);

  const updateLinks = () => {
    const tokenUserType = localStorage.getItem("userType") ?? "";

    let filtered = links;

    if (
      tokenUserType === AuthSignupPost201ResponseExternalUserTypeEnum.SALES ||
      tokenUserType === AuthSignupPost201ResponseExternalUserTypeEnum.PURCHASE
    ) {
      filtered = links.map((link) => {
        if (link.label !== "SCM") {
          return { ...link, visible: false };
        }

        const filteredSub = link.links?.map((sub) => {
          if (!("links" in sub)) return { ...sub, visible: false };

          const showSubLabel =
            tokenUserType === AuthSignupPost201ResponseExternalUserTypeEnum.PURCHASE
              ? sub.label === "영업관리(공급사)" || sub.label === "입고관리(공급사)"
              : sub.label === "영업관리(고객사)";

          const filteredInner = sub.links.filter((inner) => inner.visible);

          return {
            ...sub,
            visible: showSubLabel,
            links: showSubLabel ? filteredInner : [],
          };
        });

        return { ...link, links: filteredSub };
      });
    } else if (tokenUserType === AuthSignupPost201ResponseExternalUserTypeEnum.OUTSOURCING) {
      const allowedSubLabels = [
        t("menu.production.outsourcePerformance"),
        t("menu.production.outsource"),
        t("menu.production.slitting"),
      ];

      filtered = links
        .filter((link) => link.label === t("menu.production.production")) // 이 link만 남김
        .map((link) => {
          const filteredSubs = link.links
            ?.filter((sub) => allowedSubLabels.includes(sub.label)) // 허용된 sub만
            .map((sub) => ({
              ...sub,
              visible: true,
            }));

          return {
            ...link,
            visible: true,
            links: filteredSubs,
          };
        });
    }

    setFilteredLinks(filtered);
  };

  return (
    <Flex gap="md" align={"center"} style={{ whiteSpace: "pre-wrap" }}>
      {Array.isArray(filteredLinks) ? renderMainMenuItems(filteredLinks, t) : null}
    </Flex>
  );
};
