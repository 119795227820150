import { useEffect } from "react";

function ForceDarkModeStyles() {
  useEffect(() => {
    const applyStyles = () => {
      const style = document.createElement("style");
      style.innerHTML = `
        body[data-mantine-color-scheme="dark"] .rdg {
          background-color: #1A1B1E !important;
          color: #FFFFFF !important;
          border-color: #2C2E33 !important;
        }
        body[data-mantine-color-scheme="dark"] .rdg .rdg-cell {
          background-color: #1A1B1E !important;
          color: #FFFFFF !important;
        }
        body[data-mantine-color-scheme="dark"] .rdg-header-row {
          background-color: #25262B !important;
          color: #FFFFFF !important;
        }
      `;
      document.head.appendChild(style);
    };

    // 초기 스타일 추가
    applyStyles();

    // DOM 변경 감지 (react-data-grid 동적 스타일 감지)
    const observer = new MutationObserver(() => {
      applyStyles();
    });

    observer.observe(document.body, { childList: true, subtree: true });

    return () => observer.disconnect(); // 컴포넌트 언마운트 시 정리
  }, []);

  return null;
}

export default ForceDarkModeStyles;
