import { Flex } from "@mantine/core";

export type WorkTableWrapperProps = {
  children: React.ReactNode;
};

export const WorkTableWrapper = ({ children }: WorkTableWrapperProps) => {
  return (
    <Flex direction="column" align="flex-start" w="100%" style={{ overflowY: "auto" }}>
      {children}
    </Flex>
  );
};

// const WorkTableWrapperContainer = styled.section`
//     display: flex;
//     flex-direction: column;
//     align-items: flex-start;
//     align-self: stretch;
// `
