import { useModal } from "@/features/modal/ModalStackManager";
import { DetailBadge } from "@/features/ui/detail/DetailBadge";
import { DetailContent } from "@/features/ui/detail/DetailContent";
import { DetailField } from "@/features/ui/detail/DetailField";
import { DetailHeader } from "@/features/ui/detail/DetailHeader";
import { DetailInformTitle } from "@/features/ui/detail/DetailInformTitle";
import { DetailLabel } from "@/features/ui/detail/DetailLabel";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { DetailLinkWrapper } from "@/features/ui/detail/DetailLinkWrapper";
import { DetailTitle } from "@/features/ui/detail/DetailTitle";
import DatetimeUtil from "@/utils/dateTimeUtil";
import { Checkbox, Space } from "@mantine/core";
import { RoutingOutsourcesGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { useTranslation } from "react-i18next";

const DetailOutsource = Object.assign(DetailHeader, {
  Title: DetailTitle,
  Inform: DetailInformTitle,
  Field: DetailField,
  Label: DetailLabel,
  Link: DetailLink,
  LinkWrapper: DetailLinkWrapper,
  Badge: DetailBadge,
  Content: DetailContent,
  CheckBox: Checkbox,
});

interface RoutingOutsourceDetailHeaderProps {
  data: RoutingOutsourcesGet200ResponseRowsInner | any;
}
export const RoutingOutsourceDetailHeader = (params: RoutingOutsourceDetailHeaderProps) => {
  const { data } = params;

  const routingOutsource = data?.data?.rows[0];
  const { t } = useTranslation();

  const { openModal } = useModal();
  return (
    <>
      <DetailOutsource>
        <DetailOutsource.Title>
          [{t("common.detail", { item: `${t("outsource.outsource")}` })}]
        </DetailOutsource.Title>
        <Space h={8} />
        <DetailOutsource.Field>
          <DetailOutsource.Label>{t("outsource.name")}</DetailOutsource.Label>
          <DetailOutsource.Content>{routingOutsource?.name}</DetailOutsource.Content>
        </DetailOutsource.Field>
        <DetailOutsource.Field>
          <DetailOutsource.Label>{t("partner.code")}</DetailOutsource.Label>
          <DetailOutsource.Content>{routingOutsource?.partnerCode}</DetailOutsource.Content>
        </DetailOutsource.Field>
        <DetailOutsource.Field>
          <DetailOutsource.Label>{t("routing.code")}</DetailOutsource.Label>
          <DetailOutsource.Content>{routingOutsource?.routingCode}</DetailOutsource.Content>
        </DetailOutsource.Field>
        <DetailOutsource.Field>
          <DetailOutsource.Label>{t("outsource.fromLocationName")}</DetailOutsource.Label>
          <DetailOutsource.Content>{routingOutsource?.fromLocationName}</DetailOutsource.Content>
        </DetailOutsource.Field>
        <DetailOutsource.Field>
          <DetailOutsource.Label>{t("outsource.fromLocationCode")}</DetailOutsource.Label>
          <DetailOutsource.Link onClick={() => {}}>
            {routingOutsource?.fromLocationCode}
          </DetailOutsource.Link>
        </DetailOutsource.Field>
        <DetailOutsource.Field>
          <DetailOutsource.Label>{t("outsource.toLocationName")}</DetailOutsource.Label>
          <DetailOutsource.Content>{routingOutsource?.toLocationName}</DetailOutsource.Content>
        </DetailOutsource.Field>
        <DetailOutsource.Field>
          <DetailOutsource.Label>{t("outsource.toLocationCode")}</DetailOutsource.Label>
          <DetailOutsource.Link onClick={() => {}}>
            {routingOutsource?.toLocationCode}
          </DetailOutsource.Link>
        </DetailOutsource.Field>
        <DetailOutsource.Field>
          <DetailOutsource.Label>{t("info.createInformation")}</DetailOutsource.Label>
          <DetailOutsource.Content>
            {routingOutsource?.creatorUserCode}({DatetimeUtil(routingOutsource?.createdAt ?? "")})
          </DetailOutsource.Content>
        </DetailOutsource.Field>
      </DetailOutsource>
    </>
  );
};
