import React, { ReactNode } from "react";
import { Flex, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { IconPinned, IconPinnedFilled } from "@tabler/icons-react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { MasterApiUsersUserIdPutRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { mutateUsers, users } from "@/api/users/useUsersQuery";
import { useLocation, useNavigate } from "react-router-dom";

interface ContainerWithBookmarkProps {
  children: ReactNode;
}

export const ContainerWithBookmark: React.FC<ContainerWithBookmarkProps> = ({ children }) => {
  const { t } = useTranslation();

  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const { data: userData } = useQuery(users.who({}));

  const path2 = window.location.pathname;

  const queryParams = new URLSearchParams(location.search);

  const group = queryParams.get("group");
  const label = queryParams.get("label");

  const { mutate: updateMutate } = useMutation(
    (params: MasterApiUsersUserIdPutRequest) =>
      mutateUsers.update(params).mutationFn(params as MasterApiUsersUserIdPutRequest | any),
    { onSuccess: () => queryClient.invalidateQueries(["users"]) },
  );

  const toggleBookmark = (path: string) => {
    if (!path) return;
    const bookmarks = userData?.data?.bookmark || [];
    const updatedBookmarks = bookmarks.includes(path)
      ? bookmarks.filter((item: string) => item !== path)
      : [...bookmarks, path];

    updateMutate({
      userId: userData?.data.id as number,
      usersUserIdDeleteRequest: { bookmark: updatedBookmarks },
    });
  };

  const getBookmarkedLabel = (url: string) => {
    const queryParams = new URLSearchParams(url.split("?")[1]); // "label=전체 입출고내역&group=재고관리"
    return queryParams.get("label");
  };

  return (
    <Flex w="100%" h="100%" gap="xs" direction="column" bg="white">
      <Flex
        bg="white"
        px="sm"
        direction="row"
        gap="md"
        align="center"
        style={{
          borderBottom: userData?.data?.bookmark?.length ? "1px solid #e9ecef" : "",
          width: "100vw",
          overflowX: "auto",
          whiteSpace: "nowrap",
        }}
      >

        {/* <button

          onClick={() =>
            updateMutate({
              userId: userData?.data.id as number,
              usersUserIdDeleteRequest: { bookmark: [] },
            })
          }
        >
          리셋

        </button> */}

        <div
          onClick={() =>
            toggleBookmark(
              `${path2}?label=${label ? t(label as string) : "대시보드"}&group=${group}`,
            )
          }

          style={{ cursor: "pointer", paddingTop: "4px" }}

        >
          {userData?.data?.bookmark?.includes(
            `${path2}?label=${t(label as string)}&group=${group}`,
          ) ? (
            <IconPinnedFilled size="1.7rem" color="#495057" />
          ) : (
            <IconPinned size="1.7rem" color="#495057" />
          )}
        </div>
        {userData?.data?.bookmark?.map((bookmarked, i) => (
          <Flex
            key={bookmarked}
            bg="white"
            px="sm"
            direction="row"
            gap="xxs"
            align="center"
            style={{ cursor: "pointer", padding: "5px 0" }}
          >
            <div
              style={{
                width: "1px",
                height: "15px",
                backgroundColor: i !== 0 ? "rgb(200, 204, 209)" : "",
                marginRight: "15px",
              }}
            />
            <Text fz="lg" onClick={() => navigate(bookmarked)}>
              {getBookmarkedLabel(bookmarked)}
            </Text>
          </Flex>
        ))}
      </Flex>

      {/* ⬇️ breadcrumb/페이지설명 - 페이지마다 일괄로 적용하려면 전체적으로 포맷을 맞춰야함, 공수문제로 보류 */}
      {/* {path !== "dashboard" && (
        <Flex bg="white" px="sm" direction="row" gap="xxs" align="center">
          <div
            onClick={() => toggleBookmark(`${path2}?label=${t(label as string)}&group=${group}`)}
            style={{ cursor: "pointer", paddingRight: "10px", paddingTop: "4px" }}
          >
            {userData?.data?.bookmark?.includes(
              `${path2}?label=${t(label as string)}&group=${group}`,
            ) ? (
              <IconPinnedFilled size="1.7rem" color="#495057" />
            ) : (
              <IconPinned size="1.7rem" color="#495057" />
            )}
          </div>
          <Text fz="l" fw={500} color="#495057">
            {t(group as string)}
          </Text>
          <IconChevronRight size="1.3rem" color="#495057" />
          <Text fz="l" fw={500} color="#495057">
            {t(label as string)}
          </Text>
          <Text fz="sm" pl="10px" color="#868e96" style={{ lineHeight: 1.8 }}>
            {t("common.subTitle", { item: t(label as string) })}
          </Text>
        </Flex>
      )} */}

      {children}
    </Flex>
  );
};
